import React from 'react';
import { Layout } from '../layout/Layout';
import { Helmet } from 'react-helmet';
import { Spinner } from '@increasecard/typed-components';
import { Container as BaseContainer } from '../components/Container';
import { OnlyTextHeroSection } from '../pageSections/OnlyTextHeroSection';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import SEO from '../components/seo';

const Container = styled(BaseContainer)`
  padding-bottom: 32px;
`;

const FreshTeamWidgetWrapper = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .brand-color,
  .role-title > h5 {
    color: ${({ theme }) => theme.colorsNew.increase.regular} !important;
    font-family: 'Inter', sans-serif !important;
    font-weight: normal;
  }
  @supports (font-variation-settings: normal) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .brand-color {
      font-family: 'Inter var', sans-serif !important;
    }
  }

  .form-control:focus {
    border-color: ${({ theme }) => theme.colorsNew.increase.regular} !important;
  }
  .job-details-header {
    background-color: ${({ theme }) => theme.colorsNew.gray.black} !important;
    border-radius: 9px;
  }
  .job-details-header h1 {
    color: ${({ theme }) => theme.colorsNew.gray.white} !important;
  }
  .btn-primary,
  .upload-file {
    background-color: ${({ theme }) =>
      theme.colorsNew.increase.regular} !important;
    border-color: ${({ theme }) => theme.colorsNew.increase.regular} !important;
  }
  .btn-primary:hover,
  .upload-file:hover {
    background-color: ${({ theme }) =>
      theme.colorsNew.increase.dark} !important;
    border-color: ${({ theme }) => theme.colorsNew.increase.dark} !important;
  }
  .apply-job-button {
    background-color: ${({ theme }) => theme.colorsNew.gray.white} !important;
    border-color: ${({ theme }) => theme.colorsNew.gray.white} !important;
    color: ${({ theme }) => theme.colorsNew.increase.regular} !important;
  }
  .apply-job-button:hover {
    background-color: ${({ theme }) =>
      theme.colorsNew.increase.light} !important;
    border-color: ${({ theme }) => theme.colorsNew.increase.light} !important;
  }
  .resume-upload .btn-success,
  .btn-success {
    color: ${({ theme }) => theme.colorsNew.increase.regular} !important;
  }
  .btn-success {
    border: 2px solid ${({ theme }) => theme.colorsNew.increase.regular} !important;
  }
  .resume-upload:hover .btn-success {
    background-color: #f5f5f5;
  }
  .form-control:focus {
    border-color: ${({ theme }) => theme.colorsNew.increase.regular} !important;
  }
`;

function CareersPage({ data }) {
  const { title, subtitle, seoInfo } = data.strapi.careersPage;
  return (
    <Layout>
      <SEO
        title={seoInfo.pageTitle}
        description={seoInfo.pageDescription}
        keywords={seoInfo.keywords}
      />
      <Helmet>
        <script src="https://s3.amazonaws.com/files.freshteam.com/production/20025/attachments/2000739284/original/2000010477_widget.js?1573149856"></script>
      </Helmet>
      <OnlyTextHeroSection title={title} subtitle={subtitle} size="small" />
      <Container>
        <FreshTeamWidgetWrapper>
          <div id="freshteam-widget">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Spinner size={72} />
            </div>
          </div>
        </FreshTeamWidgetWrapper>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query {
    strapi {
      careersPage {
        seoInfo {
          pageTitle
          pageDescription
          keywords
        }
        title
        subtitle
      }
    }
  }
`;

export default CareersPage;
